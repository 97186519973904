import React from 'react';
import Box from '@material-ui/core/Box';
import ContentSection from "../components/ContentSection";
import SEO from "../components/utility/SEO";
import HomeTopSection from "../components/HomeTopSection";
import HomeBottomSection from "../components/HomeBottomSection";
import {graphql} from "gatsby";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Index({ data }) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicHomePage) return '';
  const { data: pageData } = data.prismicHomePage;

  return (
      <>
        <SEO title={pageData.meta_title ? pageData.meta_title : "Home"}/>
        <Box>
          <HomeTopSection
            title={pageData.topsection_title}
            content={pageData.topsection_content}
            image={pageData.topsection_image ? pageData.topsection_image.url : null}/>
          {pageData.body && pageData.body.map((content, index) => {
            if (!content.primary) return '';
            return (
                <ContentSection
                    key={index}
                    url={content.primary.url}
                    image={content.primary.image ? content.primary.image.url : null}
                    title={content.primary.title}
                    subtitle={content.primary.subtitle}
                    description={content.primary.description}
                    button={content.primary.button}
                    style={!matches ? {margin: '160px 0 240px'} : {margin: '80px 0 120px'}}
                    />
            )
          })}
          <HomeBottomSection
            title={pageData.bottomsection_title}
            content={pageData.bottomsection_subtitle}
            image={pageData.bottomsection_image ? pageData.bottomsection_image.url : null}/>
        </Box>
      </>
  );
}

export const homePageQuery = graphql`
    query HomePage($id: String!) {
        prismicHomePage(id: {eq: $id}) {
            data {
                meta_title
                topsection_title
                topsection_content {
                    content_title
                    content_subtitle
                }
                topsection_image {
                    url
                }
                bottomsection_title
                bottomsection_subtitle
                bottomsection_image {
                    url
                }
                body {
                    ... on PrismicHomePageBodyContentSection {
                        primary {
                            title
                            subtitle
                            description {
                              raw
                            }
                            button
                            url
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
