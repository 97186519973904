import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {headerHeight} from "./Layout";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight}px)`,
    backgroundColor: theme.palette.black
  },
  rootMobile: {
    backgroundColor: theme.palette.black
  },
  contentContainer: {
    padding: theme.spacing(4)
  },
  contentContainerMobile: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.white
  },
  description: {
    fontWeight: 300,
    color: theme.palette.white,
    maxWidth: 400
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  descriptionContainer: {
    flex: 1
  },
  imageContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  imageGradient: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: -1,
    width: theme.spacing(10),
    zIndex: 10,
    background: 'linear-gradient(to left, black, transparent)',
  }
}));

export default function HomeBottomSection(props) {
  const {title, content, image, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className={!matches ? classes.root : classes.rootMobile} {...rest}>
      <Grid item xs={5} md={4}>
        {image &&
        <div className={classes.imageContainer}>
          <img src={image} alt='' className={classes.image}/>
          <div className={classes.imageGradient}/>
        </div>
        }
      </Grid>
      <Grid item container xs={7} md={8} direction={"column"} className={!matches ? classes.contentContainer : null}>
        <div className={!matches ? classes.titleContainer : classes.contentContainerMobile}>
            <Typography variant={"h4"} className={classes.title}>
              {title}
            </Typography>
        </div>
        <div className={!matches ? classes.descriptionContainer : classes.contentContainerMobile}>
            <Typography variant={"subtitle2"} className={classes.description}>
              {content}
            </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
