import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {headerHeight} from "./Layout";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight}px)`,
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: 510,
  },
  title: {
    marginBottom: theme.spacing(8)
  },
  centeredText: {
    textAlign: 'center'
  },
  contentTitle: {
    fontWeight: 'bold',
  },
  contentSubtitle: {
    fontWeight: 300,
    textTransform: 'capitalize'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    maxHeight: '100%',
    width: '100%',
    objectPosition: 'right',
    objectFit: 'contain'
  },
  imageGradient: {
    position: 'absolute',
    top: -1,
    left: 0,
    right: 0,
    height: theme.spacing(10),
    zIndex: 10,
    background: 'linear-gradient(black, transparent)',
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}));

export default function HomeTopSection(props) {
  const {title, content, image, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDesk = useMediaQuery(theme.breakpoints.up('md'));

  return (
      <Box className={classes.root} {...rest}>
        <Fade in={matches || matchesDesk} style={{transitionDuration: '300ms'}}>
          <Grid container justify='center' alignItems='stretch'>
            <Grid xs={12} md={6} item container justify='center' alignItems='center'>
              <div className={!matches ? classes.container : null}>
                <Typography variant={'h1'}
                            color='inherit'
                            className={!matches ? classes.title : classes.centeredText}>
                  {title}
                </Typography>
                <Hidden mdUp initialWidth={'lg'}>
                  <Grid item container alignItems='center' justify='flex-end'>
                    {image &&
                    <div className={clsx(classes.imageContainer, classes.marginBottom)}
                         style={{marginBottom: '32px'}}>
                      <img src={image} alt='' className={classes.image}/>
                      <div className={classes.imageGradient}/>
                    </div>}
                  </Grid>
                </Hidden>
                {content && content.map((cont, ind) => {
                  return (
                      <div key={ind}
                           className={!matches ? classes.marginBottom : clsx(classes.centeredText, classes.marginBottom)}>
                        {cont.content_title &&
                        <Typography
                            variant={'subtitle2'}
                            color='inherit'
                            className={!matches ? classes.contentTitle : clsx(classes.contentTitle, classes.uppercase)}>
                          {cont.content_title}
                        </Typography>
                        }
                        {cont.content_subtitle &&
                        <Typography variant={'subtitle2'} color='inherit' className={classes.contentSubtitle}>
                          {cont.content_subtitle}
                        </Typography>}
                      </div>
                  )
                })}
              </div>
            </Grid>
            <Hidden smDown initialWidth={'lg'}>
              <Grid xs={12} md={6} item container alignItems='center' justify='flex-end'>
                {image &&
                <div className={classes.imageContainer}>
                  <img src={image} alt='' className={classes.image}/>
                  <div className={classes.imageGradient}/>
                </div>
                }
              </Grid>
            </Hidden>
          </Grid>
        </Fade>
      </Box>
  );
}
